.App {
  text-align: center;
  background-color: #282c34;
  color: #fff;
  height: 100vh;
  position: relative;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.backgrounds {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-icon {
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  width: 25px;
}

.contact-icons {
  display: flex;
  justify-content: space-between;
  font-size: 1.7rem;
  width: 10rem;
}

.icons {
  text-decoration: none;
}

a {
  color: inherit;
}

.instagram {
  color: #e1306c;
}

.instagram:hover {
  background-color: #e1306c;
  color: #fff;
}

.envelope {
  color: #D44638;
}

.envelope:hover {
  background-color: #D44638;
  color: #fff;
}

h1 {
  font-family: 'Monoton', cursive;
  font-size: 70px;
  margin-top: auto;
  color: rgba(250,250,250);
}

@media(max-width: 500px) {
  h1 {
    font-family: 'Monoton', cursive;
    font-size: 45px;
    margin-top: auto;
    color: rgba(250,250,250);
  }
}

h2 {
  font-family: 'Lobster Two', cursive;
  font-size: 35px;
}

p {
  font-family: 'Lobster Two', cursive;
  font-size: 30px;
}
